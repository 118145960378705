import React from 'react'

import { Route, Routes} from "react-router-dom";
import NotFound from "../components/NotFound";
import Customers from "../components/pages/Customers/Customers";

import CustomersDetail from "../components/pages/CustomersDetail/CustomersDetail";
import CreateCustomer from "../components/pages/Customers/CreateCustomer/CreateCustomer";
import Login from "../components/Login/Login";
import EditCustomer from "../components/pages/Customers/EditCustomer/EditCustomer";
import CustomerPanel from "../components/pages/CustomerPanel/CustomerPanel";


const Router: React.FC<any> = () => {
    return <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="customer">
                <Route path={'details'} element={<CustomerPanel />}>
                </Route>
            </Route>
            <Route path="admin">
                <Route path={'customers'} element={<Customers />} />
                <Route path={'create'} element={<CreateCustomer/>} />
                <Route path={'edit'}>
                    <Route index element={<NotFound/>} />
                    <Route path={':id'} element={<EditCustomer/>}/>
                </Route>
                <Route path={'detail'} >
                    <Route index element={<NotFound />} />
                    <Route path={':id'} element={<CustomersDetail/>} />
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
}
export default Router
