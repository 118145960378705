import * as actionTypes from "../main/actionTypes";
import {
    CreateCustomer,
    createCustomer,
    deleteCustomer, generateCustomerKeys,
    getKeysByCustomer,
    getListOfCustomer,
    getListOfRoles,
    logout, revokeAllKeysOfUser, revokeCustomerKey
} from "../../http/http";
import {message} from "antd";
import {CLEAR_STORE} from "../main/actionTypes";


export const getCustomersList = () => async (dispatch: DispatchType) => {
    try{
        const result = await getListOfCustomer();
        dispatch({
            type: actionTypes.SAVE_CUSTOMERS_LIST,
            payload: result.data
        })
    }catch (e){
        console.error("Get customers list is fallen ", e)
        message.error('Get customers list is fallen!');
    }
}
export const getListRoles = () => async (dispatch: DispatchType)  => {
    try {
        const result = await getListOfRoles()
        dispatch({
            type: actionTypes.SAVE_LIST_OF_ROLES,
            payload: result.data
        })
    } catch(error){
        console.error(error)
    }
}
export const removeCustomer = (customerId:any) => async(dispatch: DispatchType, getState: any) => {
    const state = getState();
    try{
        await deleteCustomer(customerId)
        const newList = state.main.customerList.filter((item: any) => item.id !== customerId)
        dispatch({
            type: actionTypes.REMOVE_CUSTOMER,
            payload: newList
        })
        message.success('Customer removed')
    }catch(er){
        message.error("Remove customer is failed")
    }
}

export const addNewCustomer = (customer: CreateCustomer) => async (dispatch: DispatchType, getState: any) => {
    const state = getState();
    try{
        const result = await createCustomer(customer)
        const customerListWithNew = [...state.main.customerList, result.data]
        dispatch({
            type: actionTypes.ADD_CUSTOMER,
            payload: customerListWithNew
        })
        message.success("Customer is created")
    }catch(err){
        message.error('Customer is not created')
    }
}
export const getListKeys =  (customerId: any ) => async (dispatch: DispatchType) => {
    try {
        const access_token = localStorage.getItem('access_token')
        const result = await getKeysByCustomer(customerId, access_token)
        dispatch({
            type: actionTypes.SAVE_CUSTOMERS_KEYS,
            payload: result.data,
        })
    }catch(e) {
        console.error('Get list of customer keys is failed !', e)
    }
}
export const addKeyToList = (userId: any, keyType: any, npmValue:any) => async (dispatch: DispatchType, getState:any ) => {
    const {main} = getState();
    try{
        const result = await generateCustomerKeys(userId, keyType, npmValue)
        const newKeyList = [...main.customerKeys, result.data]
        dispatch({
            type: actionTypes.ADD_CUSTOMER_KEY,
            payload: newKeyList
        })
        message.success('New key is generated')
    }catch(er){
        message.error('Generation of key is failed')
    }
}
export const removeKeyFromList = (userId: any, keyId: any) => async (dispatch: DispatchType, getState: any) => {
    const state = getState()
    try {
       await revokeCustomerKey(userId, keyId)
        const newListKeys = state.main.customerKeys.filter((item: any) => item.id !== keyId )
        dispatch({
            type: actionTypes.REMOVE_CUSTOMER_KEY,
            payload: newListKeys,
        })
        message.success(`User key with id ${keyId} was revoke!`);
    }catch(er){
        message.error('Key is not revoked')
    }
}
export const removeAllCustomerKeys = (userId: any) => async(dispatch: DispatchType) => {
    try {
        await revokeAllKeysOfUser(userId)
        dispatch({
            type: actionTypes.CLEAR_KEYS_LIST,
            payload: []
        })
        message.success("All keys is revoked")
    }catch(err){
        message.error("Revoke all customer keys is failed ")
    }
}

export const setAuth = () => {
    return {
        type: actionTypes.SET_AUTH,
        payload: '',
    }
}
export const performLogout = () => async (dispatch: DispatchType) => {
    try{
        await logout()
        dispatch({
            type: actionTypes.LOGOUT,
            payload: ''
        })
    }catch(e){
        console.log(e)
    }
}
export const changeActiveMenu = (menuItem: string) => {
    return {
        type: actionTypes.CHANGE_MENU_ITEM,
        payload: menuItem
    }
}
export const clearCustomerStore = () =>({type: actionTypes.CLEAR_STORE, payload: [] })
