import * as actionsType from '../main/actionTypes';
import {ADD_CUSTOMER} from "../main/actionTypes";

const initialState: MainStore = {
    customerList: [],
    auth: false,
    selectedMenu: '',
    customerKeys: [],
    rolesList: []
}

const reducer = (state: MainStore  = initialState, action: Action): MainStore => {
    switch (action.type) {
        case actionsType.SAVE_CUSTOMERS_LIST:
            return {
                ...state,
                customerList: action.payload,
            }
        case actionsType.LOGOUT:
            return {
                ...state,
                auth: false
            }
        case actionsType.SET_AUTH:{
            return {
                ...state,
                auth: true
            }
        }
        case actionsType.CHANGE_MENU_ITEM: {
            return {
                ...state,
                selectedMenu: action.payload
            }
        }
        case actionsType.SAVE_CUSTOMERS_KEYS:
            return {
                ...state,
                customerKeys: action.payload,
            }
        case actionsType.SAVE_LIST_OF_ROLES:
            return {
                ...state,
                rolesList: action.payload
            }
        case actionsType.REMOVE_CUSTOMER:
            return {
                ...state,
                customerList: action.payload
            }
        case actionsType.ADD_CUSTOMER:
            return {
                ...state,
                customerList: action.payload
            }
        case actionsType.ADD_CUSTOMER_KEY:
            return {
                ...state,
                customerKeys: action.payload
            }
        case actionsType.REMOVE_CUSTOMER_KEY:
            return {
                ...state,
                customerKeys: action.payload
            }
        case actionsType.CLEAR_KEYS_LIST:
            return {
                ...state,
                customerKeys: action.payload
            }
        case actionsType.CLEAR_STORE:
            return {
                ...initialState
            }
    }
    return state
}
export default reducer;
