export const SAVE_CUSTOMERS_LIST = 'SAVE_CUSTOMERS_LIST'
export const LOGOUT = 'LOGOUT'
export const SET_AUTH = 'SET_AUTH'
export const CHANGE_MENU_ITEM = 'CHANGE_MENU_ITEM'
export const SAVE_CUSTOMERS_KEYS = 'SAVE_CUSTOMERS_KEYS'
export const SAVE_LIST_OF_ROLES = 'SAVE_LIST_OF_ROLES'
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const ADD_CUSTOMER_KEY = 'ADD_CUSTOMER_KEY'
export const REMOVE_CUSTOMER_KEY ='REMOVE_CUSTOMER_KEY'
export const CLEAR_KEYS_LIST = 'CLEAR_KEYS_LIST'
export const CLEAR_STORE = 'CLEAR_STORE'
