import {getUserRoles} from "../http/http";

export const isAdmin = (access_token: string) => {
   // const { roles = []} = getUserRoles(access_token) as any
    const { roles = [] } = getUserInfoFromJWT(access_token) as any

    return roles.includes('admin')
}

export const getUserInfoFromJWT = (token:string) => {
    const dataFromToken = atob(token.split('.')[1])
    return JSON.parse(dataFromToken)
}
