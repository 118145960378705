import React from 'react';
import {Header} from "antd/es/layout/layout";
import {Button, Tooltip} from "antd";
import {LogoutOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {clearCustomerStore, performLogout} from "../../store/main/actionCreator";
import {Dispatch} from "redux";
import {useNavigate} from "react-router-dom";
import {clearStore} from "../../store/customer/actionCreator";

const AppHeader: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch()
    const navigate = useNavigate();

    const logout = () => {
        dispatch(performLogout())
        dispatch(clearCustomerStore())
        dispatch(clearStore())
        navigate('/login')
    }
   return <Header style={{width: '100%', zIndex: 1}}>
            <div className={'logo-sceenic'}/>
       <Tooltip title={'Press to logout'} placement={"left"}>
           <Button className={'header-logout'} onClick={() => logout()}>
               <LogoutOutlined/>
           </Button>
       </Tooltip>
    </Header>
}
export default AppHeader
