import {Buffer} from 'buffer'
import {parse} from 'uuid'

export const uidConvert = (uid:any ) => {
    if(!uid) return ''
    const id = encode(uid);
    return window.btoa(id)
    // return decode(id);
}

function encode(uuidv4:any) {
    const uint8Array = (parse)(uuidv4);
    const base64Encoded = encodeUint8Array(uint8Array);
    return base64Encoded.slice(0, -2);
}

function encodeUint8Array(uint8Array: any) {
    return Buffer.from(uint8Array).toString("base64");
}
