import React, {useEffect, useState} from 'react'
import {Button, message, Modal, PageHeader, Space, Table, Tag, Tooltip} from "antd";
import Column from 'antd/lib/table/Column';
import {getCustomersList, removeCustomer} from "../../../store/main/actionCreator";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {useNavigate} from "react-router-dom";
import {PlusCircleOutlined, DeleteOutlined, EditOutlined, CopyOutlined} from "@ant-design/icons";
import {uidConvert} from "../../../helpers/uidConvert";

const Customers: React.FC = () => {

    const navigate = useNavigate();
    const dispatch: Dispatch<any>  = useDispatch()
    const {customerList} = useSelector((state:any) => state.main)
    const [deleteCustomerConfirm, setDeleteConfirm] = useState(false);


    const cancelDeleting = () => {
        setDeleteConfirm(false)
    }
    const handleOk = () => {

        dispatch(removeCustomer(deleteCustomerConfirm))
        setDeleteConfirm(false)
    }

    useEffect(() => {
        dispatch(getCustomersList())
    }, [])

    const navigateToCustomerDetail = (id: string) => {
        navigate(`/admin/detail/${id}`)
    }
    const navigateToEditInfo = (id: string) =>{
        navigate(`/admin/edit/${id}`)
    }

    return <>
        <PageHeader
            className="site-page-header"
            backIcon={false}
            title={'Customers list'}
            //subTitle={category}
            extra={<Tooltip title={'Create new user'}><Button onClick={() => {
                navigate('/admin/create')
            }
            }><PlusCircleOutlined /></Button></Tooltip>}
        />
    <div
    style={{
        margin: 10,
    }}
    >

        <Table dataSource={customerList}>
      <Column title="Username"
              dataIndex="username" key="username"
              render={(username, record: any) => {
                return <div
                    onClick={() => navigateToCustomerDetail(record.id) }
                    style={{
                    cursor: 'pointer',

                }}> {username}</div>
              }}
      />
      <Column title="UNCT" dataIndex="unct" key="unct"
      render={(item) => {
       const value = uidConvert(item)
          return <div style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-around'
          }}><div
          style={{
              marginLeft:2,
              marginRight:2,
          }}
          > {value} </div><Tooltip title={"Press to copy"}>
              <Button onClick={() => {
                  navigator.clipboard.writeText(value)
                  message.info('The unct copied to clipboard!');
              }
              }>
                  <CopyOutlined />
              </Button>
          </Tooltip></div>
      }}
      />
        <Column title="Name" dataIndex="name" key="name" />
        {/*<Column title="Password" dataIndex="password" key="password" />*/}
        <Column title="Created" dataIndex="created" key="created"
        render={(item) => {
            if(!item) return ''
            return <div> {item} </div>
        }}
        />
        <Column title="Last login" dataIndex="last_login" key="last_login"
        render={(item) => {
            if(!item) return ''
            return <div> {item} </div>
        }}
        />
            <Column title="Actions"
            render={(item) => {
                return<div className={'customer-table-action'}><Tooltip title={'Remove customer'}>
                    <Button onClick={() => setDeleteConfirm(item.id)
                }><DeleteOutlined /></Button></Tooltip>
                    <Tooltip title={"Go to edit info"}><Button onClick={() => {
                        navigateToEditInfo(item.id)
                    }
                    }><EditOutlined /></Button></Tooltip>
                </div>
            }}
            />
    </Table>
        <Modal title="Remove customer " visible={deleteCustomerConfirm}
               onOk={() => handleOk()}
               onCancel={cancelDeleting}>
            Are you sure you want to revoke all keys of this user?
        </Modal>
    </div>
    </>
}

export default Customers
