import React, {useEffect, useState} from 'react'
import {Card, Descriptions, PageHeader, Table} from "antd";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Form, Formik, Field} from "formik";
import EditValidationScheme from "./EditValidationScheme";
import {CustomerUPDT, updateCustomerInfo} from "../../../../http/http";
import './editCustomer.css'

interface MyFormValues {
    name: string
    username: string
    password: string
    passwordConfirm: string
}

const EditCustomer = () => {
    const { id = 0 } = useParams()
    const { customerList = [] } = useSelector((state:any) => state.main)
    const [currentCustomer, setCurrentCustomer ] = useState(customerList[0])
    const initialValues: MyFormValues = {
        name: '',
        username: '',
        password: '',
        passwordConfirm: ''
    }
    useEffect(() => {
        /// todo get customer id from navigate
        // setCurrentCustomer()
        if(customerList.length > 0){
            let current = customerList.filter((item: any) => item.id === +id)[0]
            if(current){
                setCurrentCustomer(current)
            }
        }
    }, [id])

    const updateCustomer = (values: any, actions: any) => {
        const customer: CustomerUPDT = {
            name: values.name,
            username: values.username,
            password: values.password,
        }
        updateCustomerInfo(customer, id)
    }

    return <>
        <PageHeader
            className="site-page-header"
            backIcon={false}
            title={'Edit customer '}
            //subTitle={category}
        />
    <Card style={{ margin: 10}}>
        <h1>Edit customer</h1>
        <div className="edit-customer-card-body">
        <div className="edit-customer-card-element">

            {customerList &&
            <Formik
                initialValues={initialValues}
                validationSchema={EditValidationScheme}
                onSubmit={updateCustomer}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <Form style={{display: 'flex', flexDirection:'column'}}>
                        <label className={errors.name && touched.name ? 'input-label-error': ''}>{errors.name && touched.name && errors.name || "Customer Name"}</label>
                        <Field
                            className={'edit-customer-field'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            name="name"
                            placeholder="Customer Name"
                        />
                        <label className={errors.username && touched.username ? 'input-label-error': ''} >{errors.username && touched.username && errors.username || "Username"}</label>
                        <Field
                            className={'edit-customer-field'}
                            onChange={handleChange}
                                 onBlur={handleBlur}
                                 value={values.username} name="username" placeholder="Username" />
                        <label className={errors.password && touched.password ? 'input-label-error': ''}>{errors.password && touched.password && errors.password || "New password"}</label>
                        <Field
                            className={'edit-customer-field'}
                            type={"password"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            name="password" placeholder="Password" />
                        <label className={errors.passwordConfirm && touched.passwordConfirm ? 'input-label-error': ''}>{errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm || "Confirm password"}</label>
                        <Field
                            className={'edit-customer-field'}
                            type={"password"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.passwordConfirm}
                            name="passwordConfirm"
                            placeholder="Password Confirm" />
                        <button className={"submit-button"} onClick={() => handleSubmit()}>Update</button>
                    </Form>
                )}
            </Formik>
            }

        </div>
        <div className="edit-customer-card-element">
            <div className={'edit-c-detail-item'}>Customer Name:<label>{currentCustomer.name}</label></div>
            <div className={'edit-c-detail-item'}>Username: <label>{currentCustomer.username}</label></div>
        </div>
        </div>
    </Card>
        </>
}
export default EditCustomer
