import React from 'react';
//@ts-ignore
import { Wave } from 'react-animated-text';
import './loader.css'

const AppLoader:React.FC<{loading: boolean, opacity: number}> = ({loading, opacity }) => {
    if(!loading) return null;

    return <div className={'loader-container'} style={{
        backgroundColor: `rgba(0,0,0,${opacity})`
    }}>
        <Wave text="SCEENIC" effect="fadeOut" effectChange={1.0} />
    </div>
}
export default AppLoader
