import React, {useState} from 'react'
import {Button, Card, Form, Input, message} from "antd";
import {login} from "../../http/http";
import {useNavigate} from "react-router-dom";
import {isAdmin} from "../../helpers/isAdmin";
import {useDispatch} from "react-redux";
import {Dispatch} from "redux";
import {setAuth} from "../../store/main/actionCreator";
import './login.css'
import AppLoader from "../layout/loader/AppLoader";


const Login: React.FC<any> =  () => {

   const navigate = useNavigate();
   const dispatch: Dispatch<any> = useDispatch()
   const [loader, setLoader ] = useState(false)

   const onFinish = (values: any) => {
         setLoader(true)
         login({username: values.username, password: values.password}).then((result: any) => {
            // save token to local storage
            // decode token and store in to local storage
            localStorage.setItem('access_token', result.data.access)
            localStorage.setItem('refresh_token', result.data.refresh)
           // setAuth(true)
            setTimeout(() => {
               setLoader(false)
            }, 200)

            const admin = isAdmin(result.data.access)
            if(admin){
               navigate('/admin/customers')
            }else {
               navigate('/customer/details')
            }
            dispatch(setAuth())
         }).catch(error => {
            message.error("Incorrect username or password")
            setTimeout(() => {
               setLoader(false)
            }, 200)
         })

   };

   const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
   };

   return <div className={'login-container'}>
      <AppLoader loading={loader} opacity={0.97} />
       <div className={'powered-by'}> Powered by <div className={'powered-logo'}/></div>
      <Card title="LOGIN" headStyle={{textAlign:'center'}} style={{ width: 450, justifyContent:"center" }} hoverable={true}>
         <Form
             name="basic"
             labelCol={{ }}
             wrapperCol={{  }}
             initialValues={{ remember: true }}
             onFinish={onFinish}
             onFinishFailed={onFinishFailed}
             autoComplete="off"
             className={'login-form-container'}
         >
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
               <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
               <Input.Password />
            </Form.Item>


            <Form.Item wrapperCol={{  }}>
               <Button type="primary" htmlType="submit" className={'form-submit-button'}>
                  Submit
               </Button>
            </Form.Item>
         </Form>
      </Card>
   </div>
}

export default Login
