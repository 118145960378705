import * as actionsType from "./actionTypes";

const initialState: CustomerStore = {
    customerDetail: {},
    keysList: []
}

const reducer = (state: CustomerStore  = initialState, action: Action): CustomerStore => {
    switch (action.type) {
        case actionsType.SAVE_CUSTOMER_DETAIL:
            return {
                ...state,
                customerDetail: action.payload,
            }
        case actionsType.SAVE_CUSTOMER_KEYS:
            return {
                ...state,
                keysList: action.payload,
            }
        case actionsType.CLEAR_STORE:
            return {
                ...initialState
            }
    }
    return state
}
export default reducer
