import React, {useEffect, useState} from 'react'
import {Button, Card, Collapse, Descriptions, message, Modal, Table, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    addKeyToList,
    getCustomersList,
    getListKeys,
    removeAllCustomerKeys,
    removeKeyFromList
} from "../../../store/main/actionCreator";
import {Dispatch} from "redux";
import {getUserInfoFromJWT } from "../../../helpers/isAdmin";
import {EditOutlined, PlusCircleOutlined, DeleteOutlined, CopyFilled} from "@ant-design/icons";
import './customersDetail.css'
const { Panel } = Collapse;

const CustomersDetail = () => {
    const { id = 0 } = useParams()

    const dispatch:Dispatch<any> = useDispatch()
    const navigate = useNavigate();
    const { customerList = [], customerKeys = [] } = useSelector((state:any) => state.main)
    const [currentCustomer, setCurrentCustomer ] = useState(customerList[0])
    const [isAdmin, setAdmin] = useState(false)
    const [openKeyDialog, setKeyDialog] = useState(false)
    const [revokeConfirmation, setRevokeConfirmation] = useState(false)
    const [singleRevoke, setSingleRevoke] = useState(false)
    const [keyType, setKeyType] = useState('');
    const [npmValue, setNpmValue] = useState('');
    const rowStyle = {
        fontWeight: 600

    }


    const onChange = (key: string | string[]) => {
        // console.log(key);
    };
    const revokeKey = async (userId: any, keyId: any) => {
      dispatch(removeKeyFromList(userId, keyId))
        setSingleRevoke(false)
    }

    const createKeyDialog = () => {
        setKeyDialog(true)
    }
    const handleCancel = () => {
        setKeyDialog(false)
    }
    const handleOk = (id:any, keyType:any, npmValue:any ) => {
      if(keyType === 'api'){
        dispatch(addKeyToList(id, keyType, npmValue))
        setKeyDialog(false)
      }else{
          dispatch(addKeyToList(id, keyType, npmValue))
        setKeyDialog(false)
      }

    }
    const revokeAllOk = (userId: any) => {
        dispatch(removeAllCustomerKeys(userId))
        setRevokeConfirmation(false)
    }
    const revokeAllCancel = () => {
        setRevokeConfirmation(false)
    }


    useEffect(() => {
        dispatch(getListKeys(id))
        if(customerList.length === 0){
            dispatch(getCustomersList())
        }
        const token = localStorage.getItem('access_token') as string
        const {roles = []} = getUserInfoFromJWT(token)
        if(roles.includes('admin')){
            setAdmin(true)
        }

    }, [])

    useEffect(() => {
        /// todo get customer id from navigate
        // setCurrentCustomer()

        if(customerList.length > 0){
            let current = customerList.filter((item: any) => item.id === +id)[0]
            if(current){
                setCurrentCustomer(current)
            }else {
                setCurrentCustomer(customerList[0])
            }
        }
    }, [id])

    return currentCustomer && <Card style={{marginLeft: 10, marginRight: 10}} extra={isAdmin ?<>
        <Tooltip title={"Edit user info"}>
            <Button onClick={()=> {
        navigate(`/admin/edit/${id}`)
    }
    }><EditOutlined /></Button>
    </Tooltip>
    </> : <></>}>
        <Descriptions title={currentCustomer.name}>
            <Descriptions.Item label="Name" contentStyle={rowStyle}>{currentCustomer.name}</Descriptions.Item>
            <Descriptions.Item label="Username" contentStyle={rowStyle}>{currentCustomer.username}</Descriptions.Item>
            <Descriptions.Item label="Created" contentStyle={rowStyle}>{currentCustomer.created}</Descriptions.Item>
            <Descriptions.Item label="Last login" contentStyle={rowStyle}>{currentCustomer.last_login}</Descriptions.Item>
        </Descriptions>
        <div className={"keys-buttons-panel"}>
            <Tooltip title={"Revoke all customer keys"}>
                <Button onClick={() => setRevokeConfirmation(true)}><DeleteOutlined/></Button>
            </Tooltip>
            <Tooltip title={"Generate new customer key"}>
                <Button onClick={() => createKeyDialog()}><PlusCircleOutlined /></Button>
            </Tooltip>
        </div>
        <Collapse defaultActiveKey={['1']} onChange={onChange}>
        {customerKeys.map((item:any, index:number) => {
            return <><Panel header={<div
                className={'collapse-key-header'}
            ><label className={'accordion-title'}>{item.type}</label><Button onClick={() => setSingleRevoke(item.id)}>
                <DeleteOutlined />
            </Button></div> } key={index + 1} style={{textAlign: 'left'}}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <p className={'cus-detail-item'}>
                        <label className={'detail-i-label'}>User:</label>
                        {item.user}</p>
                    <p className={'cus-detail-item'}>
                        <label className={'detail-i-label'}>Secret: </label>
                        {item.secret}
                        <Tooltip title={"Press to copy"}><Button
                            className={'copy-secret-button'}
                        onClick={() => {
                            navigator.clipboard.writeText(item.secret)
                            message.info('The key copied to clipboard!');
                        }}
                    ><CopyFilled/></Button></Tooltip></p>
                    <p className={'cus-detail-item'}>
                        <label className={'detail-i-label'}>Type: </label>
                        {item.type}</p>
                    <p className={'cus-detail-item'}>
                        <label className={'detail-i-label'}>Created:</label>
                        {item.created}
                    </p>
                    <p className={'cus-detail-item'}>
                        <label className={'detail-i-label'}>Last used:</label>
                        {item.last_used}
                    </p>
                </div>
            </Panel>
            </>
        })}
            </Collapse>
        <Modal title="Create Customer Key" visible={openKeyDialog}
               onOk={() => handleOk(id, keyType, npmValue)} // todo need to use value from selector
               onCancel={handleCancel}>
            <div className={'dialog-container'}>
                <div className={'dialog-row'}>
                    <label className={'dialog-label'}>Key type: </label>
                    <select className={'dialog-input'} value={keyType} onChange={value => {
                        setKeyType(value.target.value)
                    }}>
                        <option value={"api"}>API</option>
                        <option value={"npm"}>NPM</option>
                    </select>
                </div>
                <div className={'dialog-row'}>
                    {keyType === 'npm' && <>
                        <label className={'dialog-label'}>Enter npm key: </label>
                        <input className={'dialog-input'} onChange={value => {setNpmValue(value.target.value)}} />
                    </>
                    }
                </div>
            </div>
        </Modal>
        <Modal title="Revoke all keys " visible={revokeConfirmation}
               onOk={() => revokeAllOk(id)}
               onCancel={revokeAllCancel}>
            Are you sure you want to revoke all keys of this user?
        </Modal>
        <Modal title="Revoke key " visible={singleRevoke}
               onOk={() => revokeKey(id, singleRevoke)}
               onCancel={() => setSingleRevoke(false)}>
            Are you sure you want to revoke all keys of this user?
        </Modal>
    </Card>
}
export default CustomersDetail
