import React, {useEffect, useState} from 'react';
import './App.css';
import Router from "./router/Router";
import {Layout } from "antd";
import {Content, Footer} from "antd/es/layout/layout";
import SideMenu from "./components/layout/SideMenu";
import {useNavigate} from "react-router-dom";
import AppHeader from "./components/layout/AppHeader";
import {isAdmin} from "./helpers/isAdmin";
import {useDispatch, useSelector} from "react-redux";
import {AppStore} from "./store";
import {Dispatch} from "redux";
import {setAuth} from "./store/main/actionCreator";


function App() {

    const { auth } = useSelector((state: AppStore) => state.main);
    const navigate = useNavigate()
    const dispatch: Dispatch<any> = useDispatch()
    const [admin, setAdmin ] = useState(false);

    useEffect(() => {
        // select token or role from local storage,
        // then redirect to /customer or detail of customer
        if(!auth) navigate('/login')
    }, [auth])

    useEffect(() => {
        const token = localStorage.getItem('access_token')
        if(token && isAdmin(token)){
            setAdmin(true)
        }else{
            setAdmin(false)
        }
    }, [auth])

    useEffect(() => {
        const token = localStorage.getItem('access_token')
        if(token){
            dispatch(setAuth())
            if(isAdmin(token)){
                navigate('/admin/customers')
            } else {
                navigate('/customer/details')
            }
        }
    }, [])

    if(!auth){
        return <Router />
    }

  return (<>
    <div className={admin ? "App": 'Alt_App'}>
        <Layout style={{
            display: 'flex',
            width: '100%',
            height: '100vh',
        }}>
           <AppHeader />
            <Layout>
                    {/*<div> logo </div>*/}
                  <SideMenu/>

                <Content>
                   <Router />
                </Content>
            </Layout>
            {/*<Footer  style={{width: '100%'}} />*/}
        </Layout>
    </div>
      </>
  );
}

export default App;
