import axios from 'axios';
import {getUserInfoFromJWT} from "../helpers/isAdmin";

const axiosInstance = axios.create({
     baseURL: process.env.REACT_APP_CAS_BASE
})
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.status === 403 || error.response.status === 401){
        localStorage.removeItem('access_token')
        window.location.pathname = '/login'
    }
    return Promise.reject(error);
});



interface LoginData {
    username: string
    password: string
}
export interface CreateCustomer {
    name: string
    username: string
    password: string
    role: string, // todo in future need to crate separate enum
}
export interface CustomerUPDT {
    name: string
    username: string
    password: string
}

const updateToken = async () => {
    const refresh_token = localStorage.getItem('refresh_token')
     const result = await axiosInstance.post('/auth/jwt/refresh', {
        refresh: refresh_token
    })
    localStorage.setItem('access_token', result.data.access)
    localStorage.setItem('refresh_token', result.data.refresh)
    return result.data.access
}

const getToken = () => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('access_token')
        let result = null
        if(token){
            result = getUserInfoFromJWT(token)
            const isExpirated =  result.exp - Date.now()
            if(isExpirated < 0){
                updateToken().then(token => {
                    resolve(token)
                    }
                )
            }else {
                resolve(token)
            }
        }
    })

}

export const getListOfCustomer = async () => {
    // todo select token from storage and attach to request
    const token = await getToken()
   return await axiosInstance.get(`/api/v1/users/`, {
       headers: {
           "Authorization": `Bearer ${token}`
       }
   })
}
export const getListOfRoles = async() => {
    const token = await getToken()
    return await axiosInstance.get('/api/v1/users/roles/', {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const updateCustomerInfo = async (customerUpd: CustomerUPDT, userId: any) => {
    const token = await getToken()
    return await axiosInstance.put(`/api/v1/users/${userId}`, {
        ...customerUpd
    }, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const createCustomer = async (customer: CreateCustomer) => {
    const token = await getToken()
    return await axiosInstance.post('/api/v1/users/', {
        ...customer
    }, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}
export const deleteCustomer = async (customerId: any ) => {
    const token = await getToken()
    return await axiosInstance.delete(`/api/v1/users/${customerId}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}
export const generateCustomerKeys = async (userId: any, keyType: any, npmValue: any) => {
    // if keytype npm send ready npm token
    const token = await getToken()
    let data = {
        key_type: keyType
    }
    if(keyType === 'npm'){
        //@ts-ignore
        data.secret = npmValue
    }
    return await axiosInstance.post(`/api/v1/users/${userId}/keys/`, data , {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })

}
export const revokeCustomerKey = async (userId: any, keyId: any) => {
    const token = await getToken()
    return await axiosInstance.delete(`/api/v1/users/${userId}/keys/${keyId}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}
export const revokeAllKeysOfUser = async (userId: any) => {
    const token = await getToken()
    return await axiosInstance.delete(`/api/v1/users/${userId}/keys`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}
export const login = async (credentials: LoginData) => {
    return await axiosInstance.post('/auth/jwt', {
        ...credentials
    })
}
export const logout = async () => {
    localStorage.removeItem('access_token')
}
export const getUserRoles = async (token: string) => {
    return await axiosInstance.get('/api/v1/users/roles', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const getKeysByCustomer = async (customerId: number, token: any) => {
    return await axiosInstance.get(`/api/v1/users/${customerId}/keys/`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

// customer part
export const getDetailsByCustomer = async () => {
    const token = await getToken()
    return await axiosInstance.get(`/api/v1/user/`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getCustomerKeys = async () => {
    const token = await getToken()
    return await axiosInstance.get('/api/v1/keys/', {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}


