import * as actionTypes from "./actionTypes";
import {message} from "antd";
import {getCustomerKeys, getDetailsByCustomer} from "../../http/http";
import {CLEAR_STORE} from "./actionTypes";

export const getCustomerDetails = () => async (dispatch: DispatchType) => {
    try{
        const result = await getDetailsByCustomer();
        dispatch({
            type: actionTypes.SAVE_CUSTOMER_DETAIL,
            payload: result.data
        })
    }catch (e){
        console.error("Get customers details is fallen ", e)
        message.error('Get customers details is fallen!');
    }
}
export const getCustomerKeyS = () => async (dispatch: DispatchType) => {
    try{
        const result = await getCustomerKeys()
        dispatch({
            type: actionTypes.SAVE_CUSTOMER_KEYS,
            payload: result.data
        })

    }catch(er){
        console.error('Get customers key is failed')
        message.error('Get customers key is failed')
    }
}
export const clearStore = () => async (dispatch: DispatchType) => {
    dispatch({
        type: actionTypes.CLEAR_STORE,
        payload: []
    })
}
