import React, {useEffect, useState} from 'react'
import {Button, Menu} from "antd";
import {useNavigate, useLocation} from "react-router-dom";
import {isAdmin} from "../../helpers/isAdmin";
import Sider from "antd/es/layout/Sider";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined, TeamOutlined,
} from "@ant-design/icons";
import './sideMenu.css'

const menuItemsAdmin = [
    getItem('Customers', 'customers', <TeamOutlined />)
]

function getItem(label:any , key: any, icon:any) {
    return {
        key,
        icon,
        label,
    };
}
const SideMenu: React.FC = () => {

    const [selected, setSelected ] = useState()
    const [isAdminLocal, setAdmin] = useState(false)
    const [collapsed, setCollapsed] = useState(false)
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
       const token = localStorage.getItem('access_token')
        if(isAdmin(token || '')){
           setAdmin(true)
        }
    }, [])

    useEffect(() => {
       const status = location.pathname.split('/').includes('customers')
        if(!status){
            // @ts-ignore
            setSelected('')
        }
    }, [location])

    const onChangeMenu = (item: any) => {

        setSelected(item)
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const onSelect = (item:any) => {
        console.log("item ", item )
        // const [test, category] = selected.keyPath;
        window.location.pathname.split('/').includes('admin') ?
            navigate('/admin/'+ item.key):
            navigate('/customer/' + item.key)
            setSelected(item.key)
     //   navigate(``);
       // navigate(item.keyPath[1])
    }

    if(!isAdminLocal) return null

    return    <Sider trigger={null} collapsible collapsed={collapsed}>
            <Button
                type="primary"
                onClick={toggleCollapsed}
                className={'collapse-button'}
            >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <Menu
                mode="inline"
                selectedKeys={selected}
                onSelect={onSelect}
                style={{ boxShadow: "1px 1px 3px 3px rgba(0, 191, 255, 0.1)" }}
                items={isAdminLocal ? menuItemsAdmin:[]}
            />
    </Sider>
}

export default SideMenu
