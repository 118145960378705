import { createStore, applyMiddleware, combineReducers, Store, Action } from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension';

// import auth from './auth/reducer'
import main from './main/reducer'
import customer from './customer/reducer'

const middlewares = [thunk]


export interface AppStore {
    main: MainStore,
    customer: CustomerStore
    // auth: TestsState,
}


const rootReducer = combineReducers<AppStore>({
    main,
    customer,
    // auth
})

const middlewareEnhancer = applyMiddleware(...middlewares)
const composedEnhancers = composeWithDevTools(middlewareEnhancer)

const store: Store<AppStore, Action> & {
    dispatch: DispatchType
} = createStore(rootReducer, composedEnhancers)
export default store

