import React, {useEffect, useState} from 'react'
import {Button, Card, message, Modal, Table, Tooltip, Typography, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {getCustomerDetails, getCustomerKeyS} from "../../../store/customer/actionCreator";
import Column from "antd/lib/table/Column";
import {CopyFilled, CopyOutlined, DeleteOutlined} from "@ant-design/icons";
import './customerPanle.css'
import {uidConvert} from "../../../helpers/uidConvert";


const CustomerPanel: React.FC = () => {

    const dispatch:Dispatch<any> = useDispatch()
    const { customerDetail, keysList = [] } = useSelector((store: any) => store.customer);
    const [confirmation, openConfirm ] = useState(false)

    useEffect(() => {
        dispatch(getCustomerDetails())
        dispatch(getCustomerKeyS())
    }, [])

    const handleOk = () => {
        // todo send revoke request
        openConfirm(false)
    }
    const handleCancel = () => {
        openConfirm(false)
    }


    return <>
        {customerDetail &&   <Card title={`${customerDetail.name}, welcome to the control panel`} className={'customer-card'}
        >
            <div className={'customer-card-body'}>
                <p className={'customer-detail-label'}><label className={'key-label'}>Name:</label><div className={'key-value'}>{customerDetail.name}</div></p>
                <p className={'customer-detail-label'}><label className={'key-label'}>Username:</label><div className={'key-value'}>{customerDetail.username}</div></p>
                <p className={'customer-detail-label'}><label className={'key-label'}>Last login:</label>
                    <div className={'key-value'}>{customerDetail.last_login}</div>
                </p>
                <p className={'customer-detail-label'}><label className={'key-label'}>API-Key:</label>
                    <div className={'key-container'}>
                        <Input
                        className="keys-input"
                        disabled={true}
                        value={uidConvert(customerDetail.unct)}
                    />
                        <Tooltip title={"Press to copy"}><Button className="key-input-button" onClick={() => {
                            navigator.clipboard.writeText(uidConvert(customerDetail.unct))
                            message.info('Api key was copy!');
                        }}><CopyFilled/></Button></Tooltip>
                    </div>
                </p>
                <p className={'customer-detail-label'}><label className={'key-label'}>Cas URL:</label>
                    <div className={'key-container'}>
                        <Input
                            className="keys-input"
                            disabled={true}
                            value={'https://cas.s1.sceenic.co'}
                        />
                        <Tooltip title={"Press to copy"}><Button className="key-input-button" onClick={() => {
                            navigator.clipboard.writeText("https://cas.s1.sceenic.co")
                            message.info('Cas Url was copy!');
                        }}><CopyFilled/></Button></Tooltip>
                    </div>
                    </p>
                <p className={'customer-detail-label'}><label className={'key-label'}>WT SDK Token URL:</label>
                    <div className={'key-container'}>
                        <Input
                            className="keys-input"
                            disabled={true}
                            value={'https://cas.s1.sceenic.co/stream/token/v2/'}
                        />
                        <Tooltip title={"Press to copy"}><Button className="key-input-button" onClick={() => {
                            navigator.clipboard.writeText("https://cas.s1.sceenic.co/stream/token/v2/")
                            message.info('WT SDK Token Url was copy!');
                        }}><CopyFilled/></Button></Tooltip>
                    </div>
                    </p>
                <p className={'customer-detail-label'}><label className={'key-label'}>Sync SDK Token URL :</label>
                    <div className={'key-container'}>
                        <Input
                            className="keys-input"
                            disabled={true}
                            value={'https://cas.s1.sceenic.co/sync/token/'}
                        />
                        <Tooltip title={"Press to copy"}><Button className="key-input-button" onClick={() => {
                            navigator.clipboard.writeText("https://cas.s1.sceenic.co/sync/token/")
                            message.info('Sync SDK Token URL was copy!');
                        }}><CopyFilled/></Button></Tooltip>
                    </div>
                    </p>

                {keysList.length > 0 ?
                    <Table dataSource={keysList} pagination={false}>
                        <Column title="ID"
                                dataIndex="id" key="id"
                        />
                        <Column title="Type" dataIndex="type" key="type" />
                        <Column title="Used" dataIndex="last_used" key="last_used" />
                        <Column title="Created" dataIndex="created" key="created" />
                        <Column title="Secret" dataIndex="secret" key="secret"
                                render={(item) => {
                                    return <div> {item.slice(0,15)}... <Tooltip title={"Press to copy"}><Button onClick={() => {
                                        navigator.clipboard.writeText(item)
                                        message.info('The key copied to clipboard!');
                                    }
                                    }><CopyOutlined />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                }
                                }
                        />
                        <Column title="Action" dataIndex={'action'} key={'action'}
                                render={(item) => {
                            return <div>
                                <Tooltip title={"Press for revoke your key"}>
                                    <Button onClick={() => {
                                        openConfirm(true)
                                    }
                                    }><DeleteOutlined/></Button>
                                </Tooltip>
                                </div>}}/>
                    </Table> : <p>You don't have keys yet, contact your administrator</p>
                }
            </div>
            <Typography>For get more details check our documentation <a href={'https://documentation.sceenic.co/'} target="_blank">here</a></Typography>
        </Card>}

        <div className={'customer-panel'}>
        <div className={'customer-overlay'}>
    </div>

    </div>
        <Modal title="Revoke key" visible={confirmation}
               onOk={() => handleOk()}
               onCancel={() => handleCancel()}>
            Are you sure you want to revoke this key ?
        </Modal>
        <div className={'customer-detail-footer'}> Powered by Sceenic </div></>
}
export default CustomerPanel
