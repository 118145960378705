import React, {useEffect} from 'react'
import {Card, Form, message, PageHeader} from "antd";
import {Field, Formik} from "formik";
import './createCustomer.css'
import createValidation from './createValidation'
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {addNewCustomer, getListRoles} from "../../../../store/main/actionCreator";
import {useNavigate} from "react-router-dom";

const CreateCustomer: React.FC = () => {

    const dispatch:Dispatch<any> = useDispatch()
    const navigate = useNavigate()
    const { rolesList = [] } = useSelector((store: any) => store.main)

    useEffect(() => {
        dispatch(getListRoles())
    }, [])

    const createNewCustomer = async (values: any, actions: any) => {
        const customer = {
            name: values.name,
            username: values.username,
            password: values.password,
            role: values.role,
        }
        dispatch(addNewCustomer(customer))
        navigate('/admin/customers')

        actions.setSubmitting(false);
    }


    const onFinish = (values: any) => {

    }
    const onFinishFailed = (error: any) => {
        console.log("error ", error)
    }
    const initialValues = {
        name: "Anton",
        username: "antonTestUsername",
        password: '',
        confirmPass: '',
        role: 'customer'
    }

    return <>
        <PageHeader
            className="site-page-header"
            backIcon={false}
            title={'Create new Customer '}
            //subTitle={category}
        />
    <Card style={{marginLeft: 10, marginRight: 10}}>
        <div >
            <h1 style={{textAlign:'center'}}>Create customer</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={createValidation}
                onSubmit={createNewCustomer}

            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                <Form className="create-customer-form">
                    <div className={'create-c-form-content'}>
                        <label
                            className={errors.name && touched.name ? 'input-label-error': 'create-customer-label'}
                        > {errors.name && touched.name && errors.name || "Customer Name"}</label>
                        <Field
                            className="create-customer-field"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            placeholder="Customer Name" />

                        <label
                            className={errors.username && touched.username ? 'input-label-error': 'create-customer-label'}
                        >{errors.username && touched.username && errors.username || "Username"}</label>
                        <Field
                            className="create-customer-field"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            placeholder="Username" />
                        <label
                            className={errors.password && touched.password ? 'input-label-error': 'create-customer-label'}
                        >{errors.password && touched.password && errors.password || "Password"}</label>
                        <Field
                            className="create-customer-field"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Password" />
                        <label
                            className={errors.confirmPass && touched.confirmPass ? 'input-label-error': 'create-customer-label'}
                            htmlFor="confirmPass">{errors.confirmPass && touched.confirmPass && errors.confirmPass || "Confirm password"}</label>
                        <Field
                            className="create-customer-field"
                            type="password"
                            name="confirmPass"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPass}
                            placeholder="Confirm pass" />
                        <label
                            className={'create-customer-label'}
                            htmlFor="confirmPass">Role</label>
                        <Field className={'create-customer-field'} name="role" as="select"
                               onChange={handleChange}
                               value={values.role}
                        >
                            {rolesList && rolesList.map((item: any) => {
                                return <option value={item.name}>{item.name}</option>
                            })}
                        </Field>
                        <button className={'submit-button'} onClick={() => handleSubmit()}>Create</button>
                    </div>

                </Form>)}
            </Formik>
        </div>
    </Card>
        </>
}
export default CreateCustomer;
